import { environment } from "@/environments/environment.dev";
import { AxiosClientRepository } from "../../infrastructura/Http/AxiosHttp";


export class ServiceSidebar extends AxiosClientRepository {
  async LogOutUser() {
    try {
      const url = `${environment.url_servers.url_users}/LogOutUser`;

      
      const response = await this.put({
        url,
        headers: {}
      });

      console.log('Logout Response:', response);
      return response.data; 
    } catch (error) {
      console.error('Error logging out:', error);
      throw error; 
    }
  }
}

export default ServiceSidebar; 