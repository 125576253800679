import { ArrowHide } from '@/assets/Icon/ArrowHide'
import React from 'react'

const RootVankSkeleton = () => {
    return (
        <div className="w-full flex bg-[--color-lighter-white] dark:bg-[#1A1A1B] animate-pulse">
            <div className="h-screen min-w-[236px] flex flex-col justify-between py-9 left-0 top-0 sticky z-50  bg-[--color-lightest-white] dark:bg-[#1A1A1B]  items-center">
                <div className=" max-w-[174px]  h-[575.5px] flex   flex-col gap-y-[54px] items-center">
                    <div className="min-w-[174px] min-h-[61px] bg-slate-200 dark:bg-[#2D2E2F] rounded-md" />

                    <nav className="min-w-[174px] max-h-[14.625rem] flex gap-[18px]  flex-col items-center text-lg overflow-hidden">
                        {[1, 2, 3, 4, 5, 6].map((item, index) => (
                            <li className="list-none w-full " key={index}>
                                <span
                                    key={index}
                                    className={"flex gap-4 items-center h-[24px]"}
                                >
                                    <span className='w-[24px] h-[22px] bg-slate-200 dark:bg-[#2D2E2F] rounded-md' />
                                    <p className={
                                        `h-[22px] bg-slate-200 dark:bg-[#2D2E2F] rounded-md ${item === 2 ? 'w-[85px]' :
                                            item === 5 ? 'w-[95px]' :
                                                item === 6 ? 'w-[105px]' :
                                                    'w-[75px]'
                                        }`
                                    } />
                                </span>
                            </li>
                        ))}
                    </nav>
                </div>
                <div className="w-[168px] h-[66px] ">
                    <ul className="w-full flex flex-col gap-[18px]">
                        {[1, 2].map((item, index) => (
                            <li key={index} className="">
                                <span
                                    className="flex items-center w-[7.1875rem] h-6 gap-[16px]"
                                >
                                    <span className='w-[24px] h-[22px] bg-slate-200 dark:bg-[#2D2E2F] rounded-md' />
                                    <p className={
                                        `h-[22px] bg-slate-200 dark:bg-[#2D2E2F] rounded-md ${item === 1 ? 'w-[75px]' :
                                            item === 2 && 'w-[76px]'
                                        }`
                                    } />
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <main className="relative flex flex-col w-full pt-[78px] pb-[36px] px-[64px]  h-screen overflow-hidden">
                <div className="absolute right-[64px] top-[38px] flex justify-center w-[140px] h-[48px]">
                    <header className="flex flex-col z-50  w-full h-full   items-end">

                        <div className=" w-[140px] h-[3rem] flex gap-3">
                            <div className="border-[1px] border-[#C9CBCE] rounded-full w-12  flex items-center justify-center ">
                                <div className='w-[25px] h-[25px] rounded-full bg-slate-200 dark:bg-[#2D2E2F]' />
                            </div>
                            <div className="flex items-center gap-4">
                                <div className="rounded-full w-12 h-12  bg-slate-200 dark:bg-[#2D2E2F]" />
                                <ArrowHide className="h-3 w-3" stroke="#5E6061" />
                            </div>
                        </div>
                    </header>
                </div>

                <div className="w-full h-full flex justify-center items-center gap-2">
                    <div className="w-1/2 h-[700px] flex flex-col justify-center items-center gap-8 p-8">
                        <div className='w-full h-[60%] flex flex-col justify-start items-center gap-3 bg-[#fff] dark:bg-[#2D2E2F] rounded-lg p-5'>
                            <div className='w-full h-[168px] bg-[--color-lighter-white] dark:bg-[#1A1A1B] rounded-lg'></div>
                            <span className='w-[80%] h-[22px] bg-[--color-lighter-white] dark:bg-[#1A1A1B] rounded-lg'></span>
                            <div className='w-full flex items-center gap-4'>
                                <span className='w-1/2 h-[22px] bg-[--color-lighter-white] dark:bg-[#1A1A1B] rounded-lg'></span>
                                <span className='w-1/2 h-[22px] bg-[--color-lighter-white] dark:bg-[#1A1A1B] rounded-lg'></span>
                            </div>
                        </div>
                        <div className='w-full h-[40%] bg-[#fff] rounded-lg dark:bg-[#2D2E2F]' />
                    </div>
                    <div className="w-1/2 h-[700px] flex flex-col justify-between items-center gap-8 p-8">
                        <div className='w-full h-1/2 bg-[#fff] rounded-lg dark:bg-[#2D2E2F]' />
                        <div className='w-full h-1/2 bg-[#fff] rounded-lg dark:bg-[#2D2E2F]' />
                    </div>
                </div>

            </main>
        </div>
    )
}

export default RootVankSkeleton