import CustomModal from "@/apps/Shared/CustomModal/CustomModal";
import {
  IconClose,
  IconCrypto,
  IconDolar,
  IconVVankPay,
} from "@/assets/IconV2/IconsSvg";
import { useTheme } from "@/Context/UseContext/ThemeContext";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ReceiveModal = ({
  isOpenReload,
  setIsOpenReload,
}: {
  isOpenReload: any;
  setIsOpenReload: any;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoverCrypto, setIsHoverCrypto] = useState(false);
  const [isHoverFiat, setIsHoverFiat] = useState(false);
  const [t, i18n] = useTranslation("global");

  const navigate = useNavigate();

  const { theme, toggleDarkMode } = useTheme(); // Usa el contexto

  const handleMouseHoverClose = (hovered: boolean) => {
    setIsHovered(hovered);
  };

  const handleMouseHoverCrypto = (hovered: boolean) => {
    setIsHoverCrypto(hovered);
  };

  const handleMouseHoverFiat = (hovered: boolean) => {
    setIsHoverFiat(hovered);
  };

  const handleNavigate = (path: string) => {
    setIsOpenReload(false);
    setIsHovered(false);
    setIsHoverCrypto(false);
    setIsHoverFiat(false);
    setTimeout(() => {
      navigate(path);
    }, 40);
  };

  return (
    <CustomModal isOpen={isOpenReload}>
      <div className=" 2xl:w-[396px] xl:w-[396px] lg:w-[396px] md:w-[396px] sm:w-[396px] 2xl:relative xl:relative lg:relative md:relative sm:relative mobile:absolute mobile:bottom-0    mobile:w-full h-[314px] px-4 py-6 bg-white dark:bg-[#1A1A1B] rounded-2xl flex-col justify-center items-center gap-6 inline-flex">
        <div className="w-full h-[68px]  flex-col justify-start items-start inline-flex">
          <div className="w-full h-7 justify-between items-center inline-flex">
            <span className="w-full text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-[18px] font-semibold">
              {t("Vank.Share.Sidebar.ReceiveModal.title")}
            </span>
            <div
              className="w-7 h-7 p-0 2xl:border-none xl:border-none lg:border-none md:border-none sm:border-none mobile:border border-[#818282] rounded-[500px] justify-center items-center gap-2.5 flex hover:bg-[var(--content-main-black-light)] dark:hover:bg-[#FFFFFF] transition-all duration-500 cursor-pointer"
              onMouseEnter={() => handleMouseHoverClose(true)}
              onMouseLeave={() => handleMouseHoverClose(false)}
              onClick={() => {
                setIsOpenReload(false);
                handleMouseHoverClose(false);
              }}
            >
              <IconClose
                className="w-full h-full transition-all duration-500"
                fill={
                  theme !== "dark" && isHovered
                    ? "#FFFFFF"
                    : theme === "dark" && isHovered
                    ? "#2D2E2F"
                    : "#C0C0C1"
                }
              />
            </div>
          </div>
          <span className="text-left text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-sm font-normal leading-tight">
            {t("Vank.Share.Sidebar.ReceiveModal.message")}
          </span>
        </div>

        <div className="w-full  h-[162px] justify-center items-center gap-4 inline-flex">
          <div
            className="group  hidden text-center w-[174px] h-[174px]  bg-[var(--branding-brand-yellow-light)] text-[var(--content-inner-inner-black-light)] hover:bg-[var(--content-inner-inner-black-light)] hover:text-[var(--branding-brand-yellow-light)] rounded-2xl flex-col justify-center items-center gap-3 inline-flex transition-all duration-500 cursor-pointer"
            onMouseEnter={() => handleMouseHoverCrypto(true)}
            onMouseLeave={() => handleMouseHoverCrypto(false)}
            onClick={() => handleNavigate("/receive/crypto")}
          >
            <div className="flex flex-col gap-3 group-hover:h-24 group-hover:gap-2 group-hover:transition-all group-hover:duration-500">
              <div className="w-[84px] h-[84px] flex justify-center items-center ">
                <IconCrypto
                  className="w-full h-full group-hover:h-16 group-hover:transition-all group-hover:duration-500"
                  stroke={isHoverCrypto && "var(--branding-brand-yellow-light)"}
                />
              </div>
              <div className="text-center text-lg font-semibold ">
                {t("Vank.Share.Sidebar.ReceiveModal.Actions.Crypto.title")}
              </div>
            </div>

            <span className="group-hover:block  hidden text-xs">
              {t("Vank.Share.Sidebar.ReceiveModal.Actions.Crypto.text")}
            </span>
          </div>

          <div
            className="group  w-[174px] h-[174px]  bg-[var(--branding-brand-yellow-light)] text-[var(--content-inner-inner-black-light)] hover:bg-[var(--content-inner-inner-black-light)] hover:text-[var(--branding-brand-yellow-light)] rounded-2xl flex-col justify-center items-center gap-3 inline-flex  cursor-pointer"
            onMouseEnter={() => handleMouseHoverFiat(true)}
            onMouseLeave={() => handleMouseHoverFiat(false)}
            onClick={() => handleNavigate("/receive/fiat")}
          >
            <div className="flex flex-col gap-3 group-hover:h-24 group-hover:gap-2">
              <div className="w-[84px] h-[84px] flex justify-center items-center ">
                <IconDolar
                  className="w-full h-full  group-hover:h-16"
                  stroke={isHoverFiat && "var(--branding-brand-yellow-light)"}
                />
              </div>
              <div className="text-center text-lg font-semibold ">
                {t("Vank.Share.Sidebar.ReceiveModal.Actions.Fiat.title")}
              </div>
            </div>

            <span className="group-hover:block  hidden text-xs">
              {t("Vank.Share.Sidebar.ReceiveModal.Actions.Fiat.text")}
            </span>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default ReceiveModal;
