import { IconClose, IconTooltip } from "@/assets/IconV2/IconsSvg";
import React, { useState } from "react";
import './style.css'
import { Tooltip } from "react-tooltip";


export const HeaderModal = ({
    classNameTexts,
    headerStyle,
    textStyle,
    onClick,
    text,
    subtext,
    headerHeight = "2xl:h-[58px] xl:h-[58px] lg:h-[58px] tablet:h-[58px] mobile:h-auto",
    width = "w-[553.58px]",
    subtextStyle
}: {
    onClick?: any;
    text?: string;
    subtext?: string;
    headerHeight?: string;
    width?: string;
    textStyle?: string;
    headerStyle?: string;
    classNameTexts?: string;
    subtextStyle?: string
}) => {
    const [fill, setFill] = useState<string>("var(--content-main-gray-light)");

    return (
        <header className={` flex justify-between w-full ${headerStyle}  ${headerHeight} ${width}`}>
            <div className={`${classNameTexts} `}>
                <p className={`w-full 2xl:text-[20px] xl:text-[20px] lg:text-[20px] tablet:text-[20px] mobil:text-[18px] font-semibold text-[var(--content-main-black-light)] dark:text-[#FFFFFF] flex items-center ${textStyle}`}>{text}
                    <span className="ml-1 flex">
                        <Tooltip
                            anchorSelect="#my-anchor-element"
                            place="right"
                            style={{
                                width: "300px",
                                borderRadius: "4px",
                                background: "#F8F9F9",
                                color: "#2D2E2F",
                                boxShadow: "-2px 1px 10px 0px rgba(0, 0, 0, 0.07)",
                            }}
                            border="1px solid #C0C0C1"
                        >
                        </Tooltip>
                        <div className="2xl:block xl:block lg:block tablet:block mobile:hidden">
                            <IconTooltip/>
                        </div>
                    </span>
                </p>
                <p className={` 2xl:text-[16px] xl:text-[16px] lg:text-[16px] tablet:text-[16px] mobile:text-[14px]  ${subtextStyle} dark:text-[#FFFFFF]`}>
                    {subtext}
                </p>

            </div>

            <div onMouseEnter={() => setFill('var(--content-main-white-light)')} onMouseLeave={() => setFill('var(--content-main-gray-light)')} onClick={onClick} className={` h-8 w-8 cursor-pointer rounded-full hover:bg-[var(--content-main-black-light)] `}>
                <IconClose fill={fill} />
            </div>

        </header>
    )
}
