import axios, { AxiosRequestConfig } from "axios"
import { StorageService } from "../Vank/Page/services/StorageServiceVanKPay"
import { environment } from "@/environments/environment.dev"


type RequestAxios = {
    
    url?:string 
    method?:string
    headers?:any
    data?:unknown
    timeout?:number

}


class ApiClient {

    private config:any

    private async headerRequets(){

         // Verificar si la IP ya está en localStorage
      let ip = localStorage.getItem("ip");
      const ipTimestamp = localStorage.getItem("ipTimestamp");
      const currentTime = new Date().getTime();
      const halfHour = 30 * 60 * 1000; // 30 minutos en milisegundos

       // Si la IP está almacenada pero ha pasado más de media hora, eliminarla
       if (ip && ipTimestamp && (currentTime - parseInt(ipTimestamp)) > halfHour) {
        localStorage.removeItem("ip");
        localStorage.removeItem("ipTimestamp");
        ip = null;
      }

      if (!ip) {
        // Si la IP no está almacenada, realizar la solicitud para obtenerla
        try {
          const response = await axios.get(`${environment.url_servers.url_users}/secure/v1/ip`)
          console.log(response);
          ip = response.data.body; // Obtener la IP del cuerpo de la respuesta
          // Almacenar la IP y el timestamp en localStorage
          localStorage.setItem("ip", ip);
          localStorage.setItem("ipTimestamp", currentTime.toString());
        } catch (err) {
          console.error("Error al obtener la IP:", err);
          // Lanzar un error para bloquear todas las solicitudes
          return Promise.reject(new Error("No se pudo obtener la IP. Todas las solicitudes están bloqueadas."));
        }
      }
    
        const headers = {

            Authorization:`Bearer ${await StorageService.get('token').then(resp=>resp)}`,
            Ip: ip || "",
            verify:await StorageService.get('verify') ,
            geolocation:{},
            date:new Date().toISOString()

        }
        
        return headers;
    }

    

    protected async apiClientPostAll(urlService: string, path: string, body: unknown) {
        
        try {
            this.config = {

                url:`${urlService}/${path}`,
                method: 'POST',
                headers: {},
                data: body,
               
            }

            this.config.headers=await this.headerRequets();
            const response=await axios(this.config);
            return response;

        } catch (error) {
            throw error;
        }

    }



    protected async apiClientGet(urlService: string, path: string,query:string) {

        try {

            
            this.config = {

                url:`${urlService}/${path}/`,
                method: 'GET',
                headers: {},
            
            }
           
            const headers = {

                Authorization:`Bearer ${await StorageService.get('token').then(resp=>resp)}`,
                Ip: "192.168.1.9",
                // Ip: await axios("https://api.ipify.org/").then(res=>res.data).catch(err => err),
                verify:await StorageService.get('verify') ,
                geolocation:{}   
            }

            this.config.headers = headers
            const response=await axios(this.config);
            return response;


        } catch (error) {
            throw error;
        }

    }

    
    protected async apiClientPutAll(urlService: string, path: string, body: unknown) {
  
        try {
            this.config = {

                url:`${urlService}/${path}`,
                method: 'PUT',
                headers: {},
                data: body,
               
            }
            
            this.config.headers=await this.headerRequets();
            const response=await axios(this.config);
            return response;

        } catch (error) {
            throw error;
        }

    }

    protected async apiClientMergeAll(urlService: string, path: string, body: unknown) {
        
        try {
            this.config = {

                url:`${urlService}/${path}`,
                method: 'MERGE',
                headers: {},
                data: body,
               
            }

            this.config.headers=await this.headerRequets();
            const response=await axios(this.config);
            return response;

        } catch (error) {
            throw error;
        }

    }
}

export{ApiClient}