import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions, ChartData } from 'chart.js';
import { formatBalance } from '../../Home';
import { setDefaultNamespace } from 'i18next';
import { format } from 'date-fns';

// amount?.amount?.currencies?.currencie?.amount;


const GraphBalancesMobile = ({ amountCurrency, amountCripto }: { amountCurrency: number, amountCripto: number }) => {

    ChartJS.register(ArcElement, Tooltip, Legend);
    const [operation, setOperation] = useState<string>("");


    const formate = (): string => {
        const value = amountCurrency + amountCripto;
        // Formatear valores grandes sin redondeo
        const formatValue = (val: number, divisor: number, suffix: string): string => {
            const stringValue = String(val / divisor);
            const [integerPart, decimalPart] = stringValue.split(".");
            const formattedValue = decimalPart ? `${integerPart}.${decimalPart.substring(0, 3)}` : integerPart;
            const result = `${formattedValue}${suffix}`;
            setOperation(result);
            return result;
        }

        if (value >= 1_000_000_000_000) {
            return formatValue(value, 1_000_000_000_000, "T");
        } else if (value >= 1_000_000_000) {
            return formatValue(value, 1_000_000_000, "B");
        } else if (value >= 1_000_000) {
            return formatValue(value, 1_000_000, "M");
        } else if (value >= 1_000) {
            return formatValue(value, 1_000, "K");
        } else {
            const formattedValue = value.toFixed(2); // Mantener dos decimales para valores pequeños
            setOperation(formattedValue);
            return formattedValue;
        }
    }



    const formaterValue = () => {

        const value = amountCurrency + amountCripto;

        if (value >= 1_000_000_000_000) {
            const formattedValue = (value / 1_000_000_000_000); // Redondeamos a 3 decimales
            setOperation(`${formattedValue}T`)
            return `${formattedValue}T`;
        }
        // Si el valor es mayor o igual a 1 mil millones, lo formateamos con "G"
        else if (value >= 1_000_000_000) {
            const formattedValue = (value / 1_000_000_000); // Redondeamos a 3 decimales
            setOperation(`${formattedValue}B`)
            return `${formattedValue}B`;
        }
        // Si el valor es mayor o igual a 1 millón, lo formateamos con "M"
        else if (value >= 1_000_000) {
            const formattedValue = (value / 1_000_000);
            setOperation(`${formattedValue}M`)
            return `${formattedValue}M`;
        }
        // Si el valor es mayor o igual a 1,000, lo formateamos con "k"
        else if (value >= 1_000) {
            const formattedValue = (value / 1_000);
            setOperation(`${formattedValue}K`)
            return `${formattedValue}k`;
        }
        // Si el valor es menor a 1,000, lo devolvemos tal cual
        else {
            setOperation(value.toFixed(2))
            return value.toFixed(2); // Aseguramos que tenga dos decimales
        }

    }

    useEffect(() => {
        setOperation(formate());
    }, [amountCripto, amountCurrency])

    const data: any = {
        labels: ['Segmento 1', 'Segmento 2'],
        datasets: [
            {
                data: [amountCripto > 0 ? amountCripto : 50, amountCurrency > 0 ? amountCurrency : 50],
                backgroundColor: ['#3BF2A8', '#007F73'],
                borderColor: ['#3BF2A8', '#007F73'],
                // borderWidth: 5,
                // borderRadius:3,
            },

        ],
    };

    const options: any = {
        responsive: false,
        plugins: {
            legend: {
                position: 'bottom',
                display: false
            },
            tooltip: {
                enabled: amountCurrency == 0 && amountCripto == 0 ? false : true,
                callbacks: {
                    // Personaliza el texto del tooltip
                    label: function (context: any) {
                        const label = context.label || '';
                        const value = context.raw;
                        const total = data.datasets[0].data.reduce((a, b) => a + b, 0);
                        const percentage = ((value / total) * 100).toFixed(2) + '%';
                        // Personaliza el texto del tooltip aquí
                        return ` ${percentage}`;
                    },
                    // Personaliza el título del tooltip
                    title: function () {
                        return '';
                    }
                }
            },
            datalabels: {
                display: true,
                formatter: function () {
                    return '198M';
                },
                color: '#000',
                font: {
                    weight: 'bold',
                    size: 20,
                }
            },
        },

        cutout: '70%',
        elements: {
            arc: {
                borderWidth: 0,
                borderRadius: 3, // Ajusta el borde redondeado
            },
        },
    };

    return (
        <div className='2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden mobile:block' style={{ width: 'auto', margin: '0 auto', height: 'auto', position: 'relative' }}>
            <Doughnut className='' width={159} data={data} options={options} />
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                pointerEvents: 'none'
            }}>
                <p style={{ fontSize: '12px', fontWeight: 'bold', margin: 0, color:'#818282' }}>{operation}</p>
            </div>
        </div>
    );
};

export default GraphBalancesMobile;
