import React from "react";
import next from '@assets/img/Next.png';
import ReactDOM from 'react-dom';

interface ArrowNavigationProps {
    counter: number;
    setCounter: React.Dispatch<React.SetStateAction<number>>;
}

const ArrowNavigation: React.FC<ArrowNavigationProps> = ({ counter, setCounter }) => {
    
    const portalRoot: HTMLElement | null = document.getElementById('portal-root-1');

    if (!portalRoot) {
        console.log("El portal-root no está disponible");
        return null;
    }

    // console.log("El portal-root está disponible");
    // console.log("Contenido actual de portalRoot:", portalRoot?.innerHTML);
    return ReactDOM.createPortal(
        <div className="fixed z-[99999]  h-screen left-0 h-[100px] px-2 w-full flex justify-between items-center gap-[8px] 2xl:hidden xl:hidden lg:hidden md:hidden sm:flex mobile:flex">
            <div
                className={` ${counter === 1 ? 'opacity-30' : 'opacity-100'} pointer-events-auto transform rotate-180 rounded-[999px] cursor-pointer transition-all duration-200 `}
                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                    if (counter !== 1) {
                        event.stopPropagation();
                        setCounter(counter - 1);
                        //scrollToComponent();
                    }
                }}
            >
                <img src={next} alt="Previous" className="w-[2.5rem]" />
            </div>


            <div
                className="pointer-events-auto rounded-[999px] cursor-pointer transition-all duration-200  "
                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                    event.stopPropagation(); // Evita que el clic en los botones se propague al div de fondo
                    setCounter(counter + 1);
                    //scrollToComponent();
                }}
            >
                <img src={next} alt="next" className="w-[2.5rem]" />
            </div>
        </div>,
        portalRoot
    );
};

export default ArrowNavigation;