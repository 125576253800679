import SharedButtons from "@/apps/Vank/Page/shared/SharedButtons/SharedButtons";
import { formtDate } from "@/apps/Vank/Page/utils/Utilfunctions";
import { IconClose } from "@/assets/IconV2/IconsSvg";
import COP from "@assets/img/COP.png"
import { useState } from "react";
import { useTranslation } from "react-i18next";
import succes from '@assets/img/Success.png';
import error from '@assets/img/error.png';
import pending from '@assets/img/pending.png';
import { formatBalance } from "@/apps/Vank/Page/Home/Home";

function ModalApprovedShipping({ data, onClose }: { data: any, onClose: (visible: boolean) => void }) {
    const [t] = useTranslation("global");
    const [fill, setFill] = useState<string>("var(--content-main-gray-light)");


    console.log(data);


    return (
        <div className="w-full flex items-center justify-center ">
            <div className="flex 2xl:py-[24px] xl:py-[24px] lg:py-[24px] tablet:py-[24px] mobile:py-[16px]  2xl:w-[540px] xl:w-[540px] lg:w-[540px] md:w-[540px] sm:w-[540px] mobile:w-full bottom-0 
                            2xl:relative xl:relative lg:relative md:relative sm:relative mobile:absolute  flex-col justify-center
                            items-center 2xl:2xl:gap-[24px] xl:gap-[5px] md:gap-[0px]   rounded-[16px] bg-[var(--bacground-component-module-light)] dark:bg-[#1A1A1B]">
            <div className="w-full 2xl:h-auto xl:h-auto lg:h-auto tablet:h-auto mobile-375:h-[400px] min-[360px]:h-[490px] mobile:h-[300px]
                                2xl:overflow-hidden xl:overflow-hidden lg:overflow-hidden tablet:overflow-hidden mobile:overflow-scroll gap-3 flex flex-col">
                <div className="flex  flex-col items-start gap-[16px] w-full">
                    <div className="flex  flex-col items-start justify-center  gap-[16px] w-full ">
                        <div className="flex justify-between 2xl:px-[24px] xl:px-[24px] lg:px-[24px] tablet:px-[24px] mobile:px-[16px] w-full items-center">
                            <p className="text-[var(--content-main-black-light)] 2xl:text-[20px] xl:text-[20px] lg:text-[20px] tablet:text-[20px] mobile:text-[18px] font-semibold dark:text-[#FFFFFF]">{t('Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Title')}</p>
                            <div
                                onMouseEnter={() => setFill('var(--content-inner-inner-white-light)')}
                                onMouseLeave={() => setFill('var(--content-main-gray-light)')}
                                onClick={() => onClose(false)}
                                className={`h-8 w-8 cursor-pointer rounded-full hover:bg-[var(--background-tab-selected)]`}
                            >
                                <IconClose fill={fill} />
                            </div>
                        </div>
                        <div className="h-[1px] w-full bg-[var(--strokes-separator-separator-light)] dark:bg-[#2D2E2F]"></div>
                    </div>

                </div>

                <div className="w-full flex justify-center">
                    <div className="flex w-[50px] h-[50px] justify-center items-center gap-[10px] rounded-[44px] ">
                        {data?.STATUSORDER == 1 ? <img src={succes} alt="" /> : data?.STATUSORDER == 2 ? <img src={pending} alt="" /> : <img src={error} alt="" />}
                    </div>
                </div>
                
                <div className=" flex 2xl:px-[24px] xl:px-[24px] lg:px-[24px] tablet:px-[24px] mobile:px-[16px] flex-col items-center 2xl:gap-[24px] xl:gap-[24px] lg:gap-[24px] tablet:gap-[24px] mobile:gap-5 w-full">
                    {data?.STATUSORDER == 1 ?
                        <div className="flex flex-col items-center gap-[8px] w-full">
                            <p className="text-[20px] text-[var(--content-main-black-light)] text-center font-semibold dark:text-[#FFFFFF]">{t('Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Success.Title')}</p>
                            <p className="text-[var(--content-main-gray-light)] text-center text-[14px] font-normal dark:text-[#C0C0C1]">{formtDate(data?.GENERATEDDATE)}</p>
                            <p className="text-[var(--content-main-gray-light)] text-center text-[14px] font-normal dark:text-[#C0C0C1]">{t('Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Success.TransactionId')} <span className="font-medium">{data?.CODERECEIPT}</span></p>
                        </div>
                        : data?.STATUSORDER == 2 ?
                            <div className="flex flex-col items-center gap-[8px] w-full">
                                <p className="text-[20px] text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-center font-semibold">{t('Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Process.Title')}</p>
                                <p className="text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-[14px] font-medium text-center">{t('Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Process.SubText')}</p>
                                <p className="text-[var(--content-main-gray-light)] dark:text-[#C0C0C1] text-center text-[14px] font-normal">{formtDate(data?.GENERATEDDATE)}</p>
                                <p className="text-[var(--content-main-gray-light)] dark:text-[#C0C0C1] text-center text-[14px] font-normal">{t('Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Process.TransactionId')} <span className="font-medium">{data?.CODERECEIPT}</span></p>
                            </div>
                            : <div className="flex flex-col items-center gap-[8px] w-full">
                                <p className="text-[20px] text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-center font-semibold">{t('Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Error.Title')}</p>
                                <p className="text-[var(--content-main-gray-light)] text-center text-[14px] font-normal">{formtDate(data?.GENERATEDDATE)}</p>
                                <p className="text-[var(--content-main-black-light)] dark:text-[#C0C0C1] text-[14px] font-medium text-center">{t('Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Error.SubText')} </p>
                            </div>}

                    <div className=" flex flex-col items-start gap-[16px] w-full">
                        <div className="flex justify-center items-center gap-[8px] w-full">
                            <div className="w-[28px] h-[28px] me-2">
                                <img src={COP} alt="" />
                            </div>
                            <div className=" flex items-center justify-center">
                                <p className='flex justify-center items-center font-semibold text-[18px] text-[var(--content-main-black-light)] dark:text-[#FFFFFF]'>{formatBalance(data?.AMOUNT)} <span className="font-normal mx-2"> {data?.ASSETNAME}</span></p>
                            </div>
                        </div>
                        <div className="flex p-[16px] flex-col items-start gap-[8px] rounded-[12px] bg-[var(--background-layaout-light)] dark:bg-[#2D2E2F] w-full">
                            <div className="w-full flex justify-between items-center">
                                <p className="text-[var(--content-main-gray-light)] text-[14px] font-normal dark:text-[#C0C0C1]">{t('Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.text1')}</p>
                                <p className="text-[var(--content-main-black-light)] text-[14px] font-normal dark:text-[#FFFFFF]">{data?.NAME}</p>
                            </div>
                            <div className="w-full flex justify-between items-center">
                                <p className="text-[var(--content-main-gray-light)] text-[14px] font-normal dark:text-[#C0C0C1]">{t('Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.text2')}</p>
                                <p className="text-[var(--content-main-black-light)] text-[14px] font-normal dark:text-[#FFFFFF]">{atob(data?.IDENTITYNUMBER ?? "")}</p>
                            </div>
                            <div className="h-[1px] w-full bg-[#F1F1F1] dark:bg-[#2D2E2F]"></div>
                            <div className="w-full flex justify-between items-center">
                                <p className="text-[var(--content-main-gray-light)] text-[14px] font-normal dark:text-[#C0C0C1]">{t('Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.text3')}</p>
                                <p className="text-[var(--content-main-black-light)] text-[14px] font-normal dark:text-[#FFFFFF]">{data?.BANKNAME}</p>
                            </div>
                            <div className="w-full flex justify-between items-center">
                                <p className="text-[var(--content-main-gray-light)] text-[14px] font-normal dark:text-[#C0C0C1]">{t('Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.text4')}</p>
                                <p className="text-[var(--content-main-black-light)] text-[14px] font-normal dark:text-[#FFFFFF]">{data?.BANKACCOUNTTYPENAME}</p>
                            </div>
                            <div className="h-[1px] w-full bg-[#F1F1F1] dark:bg-[#2D2E2F]"></div>
                            <div className="w-full flex justify-between items-center">
                                <p className="text-[var(--content-main-gray-light)] text-[14px] font-normal dark:text-[#C0C0C1]">{t('Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.text5')}</p>
                                <p className="text-[var(--content-main-black-light)] text-[14px] font-semibold dark:text-[#FFFFFF]">{data?.ACCOUNT_NUMBER}</p>
                            </div>
                            {/* <div className="w-full flex justify-between items-center">
                                <p className="text-[var(--content-main-gray-light)] text-[14px] font-normal">Costo transacción</p>
                                <p className="text-[var(--content-main-black-light)] text-[14px] font-normal"><span className="font-semibold">{data.FEE * 100}% </span>(${data.DISACOUNT} {data.ASSETNAME})</p>
                            </div> */}
                            <div className="h-[1px] w-full bg-[#F1F1F1] dark:bg-[#2D2E2F]"></div>
                            <div className="w-full flex justify-between items-center">
                                <p className="text-[var(--content-main-gray-light)] text-[14px] font-normal dark:text-[#C0C0C1]">{t('Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.text6')}</p>
                                <p className="text-[var(--content-main-black-light)] text-[14px] font-normal dark:text-[#FFFFFF]">{data?.DESCRIPTION}</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col items-center 2xl:gap-[24px] xl:gap-[24px] lg:gap-[24px] tablet:gap-[24px] mobile:gap-5 w-full">
                        <SharedButtons typeModule={3} URL={data?.IMGURL} TypeAction='' CODERECEIPT={data?.CODERECEIPT} email={data?.EMAIL} />
                        <div className="flex flex-col gap-[10px] w-full">
                            <button className="flex py-[10px] px-[32px] justify-center items-center gap-[8px] rounded-[999px] text-[var(--content-inner-inner-black-light)]  bg-[var(--branding-brand-yellow-light)] transition duration-300 hover:text-[var(--branding-brand-yellow-light)] hover:bg-[var(--background-tab-selected)] dark:hover:text-[#2D2E2F] dark:hover:bg-[#FFFFFF] group" onClick={() => onClose(false)}>
                                <p className="flex justify-center items-center gap-[8px]  text-[16px] font-semibold transition duration-300 ">
                                {t('Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Btn')}
                                </p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
}

export default ModalApprovedShipping;
