import CustomButton from '@/apps/Shared/CustomButton/CustomButton'
import { IconDowloadBlue, IconEmail, IconWhatSapp } from '@/assets/IconV2/IconsSvg'
import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import { postDataDownloadPdf } from '../../Transactions/VankPay/atoms/postDataDownloadPdf';
import { ServicesPrints } from '../../Transactions/VankPay/service/ServicesPrints';
import { ExternalServices } from '../../Transactions/VankPay/service/ExternalServices';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { ServicesPrintsFiat } from '../../Transactions/Fiat/Services/ServicesPrintsFiat';

const SharedButtons = ({ typeModule, email, CODERECEIPT, GENERATEDBY, URL, TypeAction }:
    { CODERECEIPT?: string, GENERATEDBY?: any, URL?: string, TypeAction?: string, email?: string, typeModule?: number }) => {

    // const dataDownloadPdf = useRecoilValue(postDataDownloadPdf);
    const printServices = new ServicesPrints()
    const printFiat = new ServicesPrintsFiat();
    const externalService = new ExternalServices()

    const [visible, setVisible] = useState(false)
    const [visibleEmail, setVisibleEmail] = useState(false)
    const [visibleWhatSapp, setVisibleWhatSapp] = useState(false)
    const [t] = useTranslation("global");
    const [dataUser, setDataUser] = useState<any>({

        EMAIL: '',
        CRYPTO: '',
        AMOUNT: '',
        CODECRYPTO: '',

    });

    useEffect(() => {

        console.log(GENERATEDBY, " GENERATEDBY");

    }, [GENERATEDBY])

    const getBase64 = async (action: string) => {

        if (typeModule == 3) {

            const dataPdf = {
                CODERECEIPT: CODERECEIPT,
                URL: URL,
                ACTION: action,
                TO_EMAIL: email,
            }

            console.log(dataPdf, "construct data pdf");


            try {
                const response = await printFiat.getPrintsFiat(dataPdf);
                console.log(response,"fiat res");
                
                // const body = response.body;
                return response.body.url==undefined?response.body:response.body.url;
            } catch (error) {
                toast.error("Error al procesar la solicitud")
            }

        } else {

            const dataPdf = {
                CODERECEIPT: CODERECEIPT,
                GENERATEDBY: GENERATEDBY,
                URL: URL,
                ACTION: action,
                TYPESEND: TypeAction,
                TO_EMAIL: email,
            }

            console.log(dataPdf, "construct data pdf");


            try {
                
                const response = await printServices.getPrints(dataPdf);
                return response.body.url==undefined?response.body:response.body.url;
            } catch (error) {
                toast.error("Error al procesar la solicitud")
            }
        }


    }


    const redirigirAWhatsApp = async () => {

        setVisibleWhatSapp(true);
        const dataBase64 = await getBase64("WHATSSAPP");

        console.log(dataBase64, " dataBase64");

        // Reemplaza con tu mensaje
        const mesagge = `Descarga tu recibo en PDF desde el siguiente enlace: ${dataBase64}`
        const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(mesagge)}`;
        window.open(url, '_blank');
        setVisibleWhatSapp(false);

    };


    // const fetchData = async () => {
    //     try {

    //         setVisible(true)

    //         // console.log(dataDownloadPdf, "data generar pdf");

    //         const response = await getBase64("DOWNLOAD");

    //         let nameFactura = CODERECEIPT
    //         // const body = response.body; // Extrae el cuerpo de la respuesta

    //         const linkSource = `data:application/pdf;base64,${response}`;

    //         // const pdfBlob = await fetch(linkSource).then(res => res.blob());
    //         // const pdfUrl = URL.createObjectURL(pdfBlob);
    //         // setPdfUrl(pdfUrl);

    //         const downloadLink = document.createElement("a");
    //         const fileName = `${nameFactura}.pdf`;
    //         console.log(fileName);

    //         downloadLink.href = linkSource;
    //         downloadLink.download = fileName;
    //         downloadLink.click();
    //         setVisible(false)
    //         toast.info("Archivo descargado")
    //     } catch (error) {
    //         setVisible(false)
    //         toast.error("Error al procesar la solicitud")
    //     }
    // };


    //para redirigir a Email

    const fetchData = async () => {
        try {

            setVisible(true)

            // console.log(dataDownloadPdf, "data generar pdf");

            const response = await getBase64("DOWNLOAD");
            console.log(response, "response");

            // const body = response.body; // Extrae el cuerpo de la respuesta

            fetch(response)
                .then(response => response.blob())
                .then(blob => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${CODERECEIPT}.pdf`; // Nombre sugerido para el archivo descargado
                    link.click();
                    window.URL.revokeObjectURL(link.href);
                })
                .catch(error => console.error('Error al descargar el PDF:', error));
            setVisible(false)
            toast.info("Archivo descargado")
        } catch (error) {
            setVisible(false)
            toast.error("Error al procesar la solicitud")
        }
    };



    const enviarEmail = async () => {

        setVisibleEmail(true)

        const dataBase64 = await getBase64("EMAIL");

        const cuerpo = `Descarga tu recibo en PDF desde el siguiente enlace: ${dataBase64}`; // Cuerpo del correo
        const url = externalService.shareEmail({ destinatario: dataUser?.EMAIL, asunto: "Resivo", cuerpo: cuerpo })

        // window.open(url, '_blank');

        setVisibleEmail(false)

        toast.info("Archivo compartido")

    };


    return (
        <div className='flex items-center gap-2 w-full   bg-[var(--background-layaout-light)] dark:bg-[#2D2E2F] dark:text-[var(--content-main-gray-dark)] h-[42px]  rounded-full relative '>
            <><ToastContainer /></>
            <CustomButton onclick={() => redirigirAWhatsApp()} label={!visibleWhatSapp ? <IconWhatSapp /> : t("cargando")} className='hover:bg-[#81828225] rounded-l-full h-full w-[33%] flex flex-col  items-center justify-center' />
            <span className='border-l-[1px] border-[var(--strokes-network-light)] dark:border-[#2D2E2F] w-1 h-6'></span>
            <CustomButton disabled={visibleEmail} onclick={() => enviarEmail()} label={!visibleEmail ? <IconEmail /> : t("cargando")} className='hover:bg-[#81828225] w-[33%] h-full  flex flex-col items-center justify-center ' />
            <span className='border-l-[1px] border-[var(--strokes-network-light)] dark:border-[#2D2E2F] w-1 h-6'></span>
            <CustomButton disabled={visible} onclick={() => fetchData()} label={!visible ? <IconDowloadBlue /> : t("cargando")} className='hover:bg-[#81828225] rounded-r-full w-[33%] h-full  flex flex-col items-center justify-center' />

        </div>
    )
}

export default SharedButtons
