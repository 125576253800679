import { useEffect, useState } from "react";
import { HeaderModal } from "../../Home/Modales/HeaderModal/HeaderModal";
import { format } from "date-fns";
import "./style.css";
import SharedButtons from "../SharedButtons/SharedButtons";
import { IconInfo } from "@/assets/IconV2/IconsSvg";
import { ServicesLibrary } from "../../Transactions/VankPay/service/ServicesLibrary";
import { ServiceDecodiToken } from "../../Transactions/VankPay/service/ServiceDecodiToken";
import { formatBalance } from "../../Home/Home";
import { IndexedDBLocalDropdownRepository } from "@/apps/Shared/infrastructura/Persistence/IndexedDBLocalDropdownRepository";
import { CustomButtonsForms } from "../CustomButtonsForms/CustomButtonsForms";
import { useTranslation } from "react-i18next";
import { formtDate } from "../../utils/Utilfunctions";

const ModalInfoTransaction = ({
  data,
  onClick,
  onclickCancel,
}: {
  data?: any;
  onClick?: () => void;
  onclickCancel?: () => void;
}) => {
  const cache = new IndexedDBLocalDropdownRepository();
  const libraryService = new ServicesLibrary();
  const tokenService = new ServiceDecodiToken();
  const [arrayCryptos, setArrayCriptos] = useState<any>([]);
  const [arrayCurrency, setArrayCurrency] = useState<any>([]);
  const [dataUser, setDataUser] = useState<any>({});
  const [infoCrypto, setInfoCrypto] = useState<any>({});
  const [urlImg, setUrlImg] = useState<string>();
  const [t] = useTranslation("global");

  const getDataCripto = async () => {
    try {
      const dataCrypto = await libraryService.getDataCripto(null);

      const infoCrypto = dataCrypto.body.filter((item: any) =>
        item.ASSET.includes(data.ASSET)
      );

      if (infoCrypto.length != 0) {
        setInfoCrypto(infoCrypto[0]);
      } else {
        const dataCountry = await libraryService.getDataCountry(null);
        console.log(dataCountry, "kk");

        const infoCountry = dataCountry.body.filter((item: any) =>
          item.ISO.includes(data.ASSET)
        );

        console.log(infoCountry[0].IMGURL, " infoCountr");

        setInfoCrypto(infoCountry[0]);
      }

      const dataToken = await tokenService.getVirtualEmail();

      setDataUser(dataToken);
    } catch (error) {
      console.log(error);
    }
  };

  const getDataUser = async () => {
    const data = await tokenService.getVirtualEmail();

    setDataUser(data);
  };

  useEffect(() => {
    // getDataCripto()
    getDataUser();
    console.log(data);
  }, []);

  const getDataEntries = (object: any): any => {
    let resul = "";
    const entriesCurrencie = Object.entries(object.value);

    entriesCurrencie.map((item: any, index) => {
      if (item[1].ISO == data.ASSET) {
        resul = item[1].IMGURL;
      } else {
        if (item[1].ASSET == data.ASSET) {
          resul = item[1].IMGURL;
        }
      }
    });

    return resul;
  };

  useEffect(() => {
    const fechtData = async () => {
      const dataCurrencie: any = await cache.get("localCurrency");
      const dataCrypto: any = await cache.get("localWallet");

      let result =
        getDataEntries(dataCurrencie) == ""
          ? getDataEntries(dataCrypto)
          : getDataEntries(dataCurrencie);

      setUrlImg(result);
    };

    fechtData();
  }, []);

  return (
    <div
      className="bg-[var(--bacground-component-module-light)] dark:bg-[#1A1A1B]
                2xl:w-[500px] xl:w-[500px] lg:w-[500px] md:w-[500px] sm:w-[500px] mobile:w-full bottom-0 
                2xl:relative xl:relative lg:relative md:relative sm:relative mobile:absolute
                p-4 2xl:rounded-2xl xl:rounded-2xl lg:rounded-2xl tablet:rounded-2xl mobile:rounded-t-2xl  flex flex-col gap-6"
    >
      <HeaderModal
        headerStyle="w-full "
        headerHeight=""
        textStyle="textitle"
        text={`${
          data.ACTION == "INPUT"
            ? t(
                "Page.shared.ModalInfoTransaction.ModalInfoTransaction.VankpayTransactionReceived"
              )
            : t(
                "Page.shared.ModalInfoTransaction.ModalInfoTransaction.VankpayShippingApproved"
              )
        }`}
        onClick={onClick}
      />

      <div className="flex flex-col gap-[16px]">
        <header className="flex flex-col items-center gap-4">
          <div className="flex gap-1 items-center justify-center">
            <img
              src={
                data.ASSET == "COP"
                  ? "https://images-vank.s3.us-west-2.amazonaws.com/emojione--flag-for-colombia.png"
                  : "https://images-vank.s3.us-west-2.amazonaws.com/circle-flags--us.png"
              }
              alt=""
              className="h-[28px] w-[28px]"
            />
            <p className="font-[600] text-lg dark:text-[#FFFFFF]">
              {formatBalance(data.AMOUNT)}
            </p>
            <p className="dark:text-[#FFFFFF]">{data.ASSET}</p>
          </div>

          <div className="text-center text-[var(--content-main-gray-light)] dark:text-[#C0C0C1]">
            <p>{formtDate(data.GENERATEDDATE)}</p>
            <p>
              {t(
                "Page.shared.ModalInfoTransaction.ModalInfoTransaction.TransactionID"
              )}
              : {data.CODERECEIPT}
            </p>
          </div>
        </header>
        <section className="w-full bg-[var(--background-layaout-light)] dark:bg-[#2D2E2F] p-4 rounded-xl flex flex-col gap-2">
          <div className="section-info ">
            <p className="text-info dark:text-[#C0C0C1]">
              {data.ACTION == "OUTPUT"
                ? t(
                    "Page.shared.ModalInfoTransaction.ModalInfoTransaction.Sendto"
                  )
                : t(
                    "Page.shared.ModalInfoTransaction.ModalInfoTransaction.Sentby"
                  )}
            </p>
            <p className="dark:text-[#FFFFFF] 2xl:w-[15rem] xl:w-[15rem] lg:w-[13rem] mobile-375:w-[12rem] min-[360px]:w-[12rem] mobile:w-[11rem] break-words text-end">
              {data.ACTION == "OUTPUT"
                ? data.TOSUBACCOUNTID
                : data.NAMERECEPTOR}
            </p>
          </div>
          <div className="section-info">
            <p className="text-info dark:text-[#C0C0C1]">
              {t(
                "Page.shared.ModalInfoTransaction.ModalInfoTransaction.Paymentorigin"
              )}
            </p>
            <p className="dark:text-[#FFFFFF]">{data.ASSET} Vank Wallet</p>
          </div>
          <div className="section-info">
            <p className="text-info dark:text-[#C0C0C1]">
              {t(
                "Page.shared.ModalInfoTransaction.ModalInfoTransaction.Transactionalfee"
              )}
            </p>
            <p className="dark:text-[#FFFFFF]">
              {t("Page.shared.ModalInfoTransaction.ModalInfoTransaction.Free")}
            </p>
          </div>
        </section>

        {data.USUARIOVANK === "EXTERNO" && (
          <div className="flex items-center  px-6 py-2 rounded-xl gap-4 bg-[#FFF3DB]">
            <IconInfo
              id="my-anchor-element"
              className="cursor-pointer  peer w-8 h-8"
            />

            <p className="text-sm text-[var(--content-inner-inner-black-light)] ">
              {t("Page.shared.ModalInfoTransaction.ModalInfoTransaction.Text1")}{" "}
              <br />{" "}
              {t("Page.shared.ModalInfoTransaction.ModalInfoTransaction.Text2")}
            </p>
          </div>
        )}

        <section>
          <SharedButtons
            TypeAction={data.ACTION}
            CODERECEIPT={data.CODERECEIPT}
            GENERATEDBY={dataUser}
            URL={
              data.ASSET == "COP"
                ? "https://images-vank.s3.us-west-2.amazonaws.com/emojione--flag-for-colombia.png"
                : "https://images-vank.s3.us-west-2.amazonaws.com/circle-flags--us.png"
            }
            email={data.TOSUBACCOUNTID}
          />
        </section>
        {data.STATUSORDER == 2 && (
          <section>
            <CustomButtonsForms
              type="button"
              labelClear={t("Page.shared.ModalInfoTransaction.ModalInfoTransaction.Cancel")}
              label={t("Page.shared.ModalInfoTransaction.ModalInfoTransaction.CancelTransaction")}
              onClickClear={onClick}
              onClickContinue={onclickCancel}
            />
          </section>
        )}
      </div>
    </div>
  );
};

export default ModalInfoTransaction;
